<template>
  <a-modal
    class="modal-promo"
    :width="600"
    :footer="null"
    :mask-closable="false"
    :visible="visible"
    :dialog-style="dialogStyle"
    :title="$t('discount.add_promotion')"
    @cancel="onCloseModal"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rulesPromo"
    >
      <a-row :gutter="16">
        <a-col :span="isQuantity ? 8 : 12">
          <a-form-model-item
            :label="$t(`discount.${isQuantity ? 'min_qty' : 'min_trx'}`)"
            prop="min"
          >
            <InputNumber
              v-model="form.min"
              size="large"
              :placeholder="$t(`discount.${isQuantity ? 'min_qty' : 'min_trx'}`)"
              @blur="checkMinMax"
            />
            <div v-if="result < 0" class="ant-form-explain" style="color: #f5222e">
              Min lebih besar Max
            </div>
          </a-form-model-item>
        </a-col>
        <a-col :span="isQuantity ? 8 : 12">
          <a-form-model-item
            :label="$t(`discount.${isQuantity ? 'max_qty' : 'max_trx'}`)"
            prop="max"
          >
            <InputNumber
              v-model="form.max"
              size="large"
              :placeholder="$t(`discount.${isQuantity ? 'max_qty' : 'max_trx'}`)"
              @blur="checkMinMax"
            />
            <div v-if="result < 0" class="ant-form-explain" style="color: #f5222e">
              Max lebih kecil Min
            </div>
          </a-form-model-item>
        </a-col>
        <a-col v-if="isQuantity" :span="8">
          <a-form-model-item
            :label="$t('discount.uom')"
            prop="uom"
          >
            <a-select
              v-model="form.uom"
              size="large"
              class="select-antd-default"
              :placeholder="$t('discount.uom')"
              allow-clear
            >
              <a-select-option v-for="uom in uoms" :key="uom">
                {{ uom }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="criteria === 'multiple'" :gutter="16">
        <a-col :span="isQuantity ? 8 : 12">
          <a-form-model-item
            :label="$t('discount.multiple_value')"
            prop="multiple"
          >
            <InputNumber
              v-model="form.multiple"
              size="large"
              :placeholder="$t('discount.multiple_value')"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <div class="scroll-div">
        <a-card
          v-for="(gift, index) in form.gift"
          :key="index"
          class="card-gift"
        >
          <span class="card-gift-title">Hadiah {{ index + 1 }}</span>
          <a-icon
            v-if="form.gift.length > 1"
            type="close-circle"
            theme="filled"
            class="close-btn"
            @click="onDeleteGift(index)"
          />
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-model-item
                :label="$t('discount.discount_format')"
                :rules="rules.value_type"
                :prop="'gift.' + index + '.value_type'"
              >
                <a-select
                  v-model="gift.value_type"
                  size="large"
                  class="select-antd-default"
                  :placeholder="$t('discount.discount_format')"
                  :options="formats"
                  allow-clear
                  @change="onChangeFormat(index)"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <template v-if="gift.value_type">
            <a-row v-if="gift.value_type !== 'product'" :gutter="16">
              <a-col v-if="gift.value_type === 'percentage'" :span="8">
                <a-form-model-item
                  class="mb-0"
                  :label="$t('discount.percentage')"
                  :rules="rules.value"
                  :prop="'gift.' + index + '.value'"
                >
                  <InputNumber
                    v-model="gift.value"
                    size="large"
                    addon-after="%"
                    :placeholder="$t('discount.percentage')"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item
                  class="mb-0"
                  :label="$t('discount.currency')"
                  :rules="rules.currency"
                  :prop="'gift.' + index + '.currency'"
                >
                  <a-select
                    v-model="gift.currency"
                    size="large"
                    class="select-antd-default"
                    :placeholder="$t('discount.currency')"
                  >
                    <a-select-option value="Rp">
                      Rp
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="gift.value_type === 'percentage' ? 10 : 18">
                <a-form-model-item
                  class="mb-0"
                  :label="$t('discount.discount_max')"
                  :rules="rules.max_value"
                  :prop="'gift.' + index + '.max_value'"
                >
                  <InputNumber
                    v-model="gift.max_value"
                    size="large"
                    :placeholder="$t('discount.discount_max')"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            
            <a-row v-else :gutter="16">
              <a-col :span="8">
                <a-form-model-item
                  class="mb-0"
                  label="Qty"
                  :rules="rules.qty"
                  :prop="'gift.' + index + '.qty'"
                >
                  <InputNumber
                    v-model="gift.qty"
                    size="large"
                    placeholder="Qty"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item
                  class="mb-0"
                  :label="$t('discount.uom')"
                  :rules="rules.uom"
                  :prop="'gift.' + index + '.uom'"
                >
                  <a-select
                    v-model="gift.uom"
                    size="large"
                    class="select-antd-default"
                    :placeholder="$t('discount.uom')"
                  >
                    <a-select-option v-for="uom in uoms" :key="uom">
                      {{ uom }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item
                  class="mb-0"
                  :label="$t('discount.product_name')"
                  :rules="rules.catalog_id"
                  :prop="'gift.' + index + '.catalog_id'"
                >
                  <a-select
                    v-model="gift.catalog_id"
                    size="large"
                    class="select-antd-default"
                    placeholder="Cari & Pilih Produk"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    show-search
                    @change="addProduct"
                    @search="fetchProduct"
                  >
                    <a-spin v-if="fetching" slot="notFoundContent" />
                    <a-select-opt-group v-for="prod in products" :key="prod.id">
                      <span slot="label">
                        {{ prod.title.length > 27 ? prod.title.substring(0, 25) + '...' : prod.title }}
                      </span>
                      <a-select-option
                        v-for="catalog in prod.catalogs" 
                        :key="`${catalog.id}.${catalog.title}`"
                      >
                        <template v-if="(catalog.title.length + catalog.sku.length) > 58">
                          <a-tooltip placement="left">
                            <template slot="title">
                              {{ catalog.title }}
                              <span v-if="prod.catalogs && prod.catalogs.length">
                                {{ `- SKU: ${catalog.sku}` }}
                              </span>
                            </template>
                            {{ catalog.title }}
                            <span v-if="prod.catalogs && prod.catalogs.length">
                              {{ `- SKU: ${catalog.sku}` }}
                            </span>
                          </a-tooltip>
                        </template>
                        <template v-else>
                          {{ catalog.title }}
                          <span v-if="prod.catalogs && prod.catalogs.length">
                            {{ `- SKU: ${catalog.sku}` }}
                          </span>
                        </template>
                      </a-select-option>
                    </a-select-opt-group>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
          </template>
        </a-card>
      </div>

      <div class="d-flex align-items-center pt-3 border-t">
        <a-button
          size="large"
          class="bg-primary-blue text-white"
          @click="onAddGift"
        >
          {{ $t('utils.add') }} Hadiah
        </a-button>
        <div class="ml-auto text-right">
          <a-button
            type="primary"
            size="large"
            class="mr-4"
            ghost
            @click="onCloseModal"
          >
            {{ $t('utils.cancel') }}
          </a-button>
          <a-button
            type="primary"
            size="large"
            @click="onSubmit"
          >
            {{ $t('utils.save') }}
          </a-button>
        </div>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import debounce from 'lodash/debounce'
import { getProduct } from '@/api/channels/distributor'
import { rulesPromotion, rulesGift } from '@/data/rules'
import { useInputNumber } from '@/composable/useInputNumber'
import InputNumber from '@/components/Input/InputNumber.vue'

const INITIAL_FORM = {
  uom: 'PCS',
  min: '',
  max: '',
  multiple: '',
  gift: [{
    type: 'discount',
    value_type: undefined,
    value: '',
    max_value: '',
    catalog_id: '',
    qty: '',
    currency: 'Rp',
    uom: 'PCS',
  }],
}

export default {
  name: 'ModalAddPromotion',
  components: {
    InputNumber,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    program: {
      type: String,
      default: 'product',
    },
    criteria: {
      type: String,
      default: 'range',
    },
    criteriaPromo: {
      type: String,
      default: 'quantity',
    },
    promo: {
      type: Object,
      default: () => {},
    },
    businessId: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { toNumber } = useInputNumber()
    return { toNumber }
  },
  data() {
    this.fetchProduct = debounce(this.fetchProduct, 500)
    return {
      result: 0,
      fetching: false,
      product: {},
      products: [],
      uoms: ['PCS', 'CTN', 'BOX'],
      formats: [
        { label: this.$t('discount.percentage'), value: 'percentage' },
        { label: this.$t('discount.fixed'), value: 'fixed' },
        { label: this.$t('discount.product'), value: 'product' },
      ],
      rulesPromo: rulesPromotion,
      rules: rulesGift,
      dialogStyle: {
        top: '20px',
      },
      form: {},
    }
  },
  computed: {
    isQuantity() {
      return this.program === 'product' && this.criteriaPromo === 'quantity'
        ? true
        : false
    },
  },
  watch: {
    visible(newValue) {
      if (!newValue) {
        this.result = 0
        if (this.$refs.ruleForm) this.$refs.ruleForm.resetFields()
      } else {
        this.products = this.list
      }
    },
    promo(newValue) {
      if (newValue?.min) {
        this.form = JSON.parse(JSON.stringify(newValue))
      } else {
        this.form = JSON.parse(JSON.stringify(INITIAL_FORM))
      }
    },
  },
  methods: {
    checkMinMax() {
      if (this.form.min && this.form.max) {
        this.result = this.toNumber(this.form.max) - this.toNumber(this.form.min)
      }
    },
    async fetchProduct(value = '') {
      if(value === '') {
        this.products = this.list
      } else {
        this.fetching = true
        const bodyValue = {
          id: this.$route.params.id,
          businessId: this.businessId,
          params: {
            search_query: value ? value : '',
            page: 1,
            limit: 10,
            order: 'createdAt',
            sort: 'title',
            status: 'ACTIVE',
          },
        }
        await getProduct(bodyValue)
          .then(({ data }) => this.products = data.data)
          .catch(() => this.products = [])
          .then(() => this.fetching = false)
      }
    },
    addProduct(value) {
      let length = this.products.length
      for(let i = 0; i < length; i++) {
        const catalog = this.products[i].catalogs.find(item => item.id == value.split('.')[0])
        if(catalog?.id) {
          this.product = { ...this.products[i], catalogs: [catalog] }
          length = i + 1
        }
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid && this.result >= 0) {
          this.$emit('add', this.product)
          this.$emit('submit', JSON.parse(JSON.stringify(this.form)))
        }
      })
    },
    onAddGift() {
      this.form.gift.push({
        type: 'discount',
        value_type: undefined,
        value: '',
        max_value: '',
        catalog_id: '',
        qty: '',
        currency: 'Rp',
        uom: 'PCS',
      })
    },
    onDeleteGift(index) {
      this.form.gift.splice(index, 1)
    },
    onChangeFormat(index) {
      this.form.gift[index].value = ''
      this.form.gift[index].max_value = ''
      this.form.gift[index].qty = ''
      this.form.gift[index].catalog_id = ''
      if (this.$refs.ruleForm) this.$refs.ruleForm.clearValidate()
    },
    onCloseModal() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.border-t {
  border-top: 1px solid #E9ECEF;
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #c8c4db;
  cursor: pointer;

  &:hover {
    color: #9DA3AA;
  }
}
.card-gift {
  margin-top: 5px;
  margin-bottom: 1.5rem;

  &-title {
    position: absolute;
    width: 80px;
    top: -10px;
    left: 15px;
    text-align: center;
    color: #495057;
    background-color: #fff;
  }
}
</style>
<style lang="scss">
.modal-promo .ant-modal-body {
  padding-top: 5px !important;
}
</style>
